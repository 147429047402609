module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-styled-components@6.9.0_babel-plugin-styled-components@2.1.3_styled-components@_mjujgxwz747xzm7gohzhlwhmdu/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-analytics@5.9.0_gatsby@5.9.0_babel-eslint@10.1.0_eslint@7.32.0__react-do_q5sxoaopzg76kc6aidwps4ppc4/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-92261711-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.9.0_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.2.0_react@18.2.0__react@18.2.0_t_utdbi2za6uozpwgedopdgrq2zm/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
